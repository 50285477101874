<template>
  <app-modal :show="showPaymentGatewayMethodModal" @close="closePaymentGatewayMethodModal" :showClose="false">
    <template v-slot:title>
      <app-tab :tabs="tabs" :selected_tab="selectedTabs" @tab-changed="selectedTabs = $event" />
    </template>
    <template v-slot:body>
      <div class="space-y-3" v-if="selectedTabs == 'gateway'">
        <app-form-select id="gateway_charges_type" name="gateway_charges_type" type="number"
          :labelText="$t('payment.menu.charges_type')" :datas="chargesType" :loading="loadingChargesType"
          :allowFilter="false" :errorMessage="errorsPayment.charges_type"
          v-model="selectedPaymentGatewayMethod.gateway_charges_type" v-on:retry="fetchChargesType" />

        <app-form-input v-if="
          selectedPaymentGatewayMethod.gateway_charges_type != null &&
          selectedPaymentGatewayMethod.gateway_charges_type?.type != PAYMENT_CHARGE_TYPE.PERCENTAGE
        " id="gateway_fixed_charges" name="gateway_fixed_charges" step=".01" type="number"
          v-model="selectedPaymentGatewayMethod.formatted_gateway_fixed_charges"
          :errorMessage="errorsPayment.gateway_fixed_charges" :labelText="$t('payment.menu.fixed_charges')" />

        <app-form-input v-if="
          selectedPaymentGatewayMethod.gateway_charges_type != null &&
          selectedPaymentGatewayMethod.gateway_charges_type?.type != PAYMENT_CHARGE_TYPE.FIXED
        " id="gateway_percentage_charges" name="gateway_percentage_charges" step=".01" type="number"
          v-model="selectedPaymentGatewayMethod.gateway_percentage_charges"
          :errorMessage="errorsPayment.gateway_percentage_charges" :labelText="$t('payment.menu.percentage_charges')" />

        <app-form-input v-if="
          selectedPaymentGatewayMethod.gateway_charges_type != null &&
          selectedPaymentGatewayMethod.gateway_charges_type?.type != PAYMENT_CHARGE_TYPE.FIXED
        " id="gateway_percentage_min_charges" name="gateway_percentage_min_charges" step=".01" type="number"
          v-model="selectedPaymentGatewayMethod.formatted_gateway_percentage_min_charges"
          :errorMessage="errorsPayment.gateway_percentage_min_charges" :labelText="
            $t('payment.menu.fixed_min_charges', {
              charge_type: selectedPaymentGatewayMethod.gateway_charges_type?.name,
            })
          " />

        <app-button :showf70Icon="false" class="mt-4 pt-2 pb-2" @onClick="submitBusinessPaymentGatewayMethod"
          :loading="loadingUpdate">
          {{ $t("general.submit") }}
        </app-button>
      </div>

      <div class="space-y-3" v-if="selectedTabs == 'template'">
        <app-form-select id="template_charges_type" name="template_charges_type" type="number"
          :labelText="$t('payment.menu.charges_type')" :datas="chargesType" :loading="loadingChargesType"
          :allowFilter="false" :errorMessage="errorsPayment.charges_type"
          v-model="selectedPaymentGatewayMethod.template_charges_type" v-on:retry="fetchChargesType" />

        <app-form-input v-if="
          selectedPaymentGatewayMethod.template_charges_type != null &&
          selectedPaymentGatewayMethod.template_charges_type?.type != PAYMENT_CHARGE_TYPE.PERCENTAGE
        " id="template_fixed_charges" name="template_fixed_charges" step=".01" type="number"
          v-model="selectedPaymentGatewayMethod.formatted_template_fixed_charges"
          :errorMessage="errorsPayment.template_fixed_charges" :labelText="$t('payment.menu.fixed_charges')" />

        <app-form-input v-if="
          selectedPaymentGatewayMethod.template_charges_type != null &&
          selectedPaymentGatewayMethod.template_charges_type?.type != PAYMENT_CHARGE_TYPE.FIXED
        " id="template_percentage_charges" name="template_percentage_charges" step=".01" type="number"
          v-model="selectedPaymentGatewayMethod.template_percentage_charges"
          :errorMessage="errorsPayment.template_percentage_charges"
          :labelText="$t('payment.menu.percentage_charges')" />

        <app-form-input v-if="
          selectedPaymentGatewayMethod.template_charges_type != null &&
          selectedPaymentGatewayMethod.template_charges_type?.type != PAYMENT_CHARGE_TYPE.FIXED
        " id="template_percentage_min_charges" name="template_percentage_min_charges" step=".01" type="number"
          v-model="selectedPaymentGatewayMethod.formatted_template_percentage_min_charges"
          :errorMessage="errorsPayment.template_percentage_min_charges" :labelText="
            $t('payment.menu.fixed_min_charges', {
              charge_type: selectedPaymentGatewayMethod.template_charges_type?.name,
            })
          " />

        <app-button :showf70Icon="false" class="mt-4 pt-2 pb-2" @onClick="submitBusinessPaymentGatewayMethod"
          :loading="loadingUpdate">
          {{ $t("general.submit") }}
        </app-button>
      </div>
    </template>
  </app-modal>

  <app-layout class="bg-background">
    <div class="container mx-auto">
      <app-table :apiResponse="paymentGatewayMethods" :showEmptyButton="false" @params-changed="paramsChanged"
        @on-item-click="openPaymentGatewayMethodModal" :loading="apiPaginationLoading" :filters="[
          {
            key: 'filter[payment_method_name]',
            type: 'text',
            placeholder: $t('payment.payment-providers.header.payment_method'),
            value: ''
          },
          {
            key: 'filter[payment_gateway_name]',
            type: 'text',
            placeholder: $t('payment.payment-providers.header.payment_gateway'),
            value: ''
          },
          {
            key: 'filter[channel]',
            type: 'text',
            placeholder: $t('payment.payment-providers.header.channel'),
            value: ''
          },
        ]">
        <template v-slot:header>
          <td>
            <b>{{ $t("payment.payment-providers.header.payment_method") }}</b>
          </td>
          <td>
            <b>{{ $t("payment.payment-providers.header.payment_gateway") }}</b>
          </td>
          <td>
            <b>{{ $t("payment.payment-providers.header.channel") }}</b>
          </td>
          <td>
            <b>{{ $t("payment.payment-providers.header.gateway_charges") }}</b>
          </td>
          <td>
            <b>{{ $t("payment.payment-providers.header.template_charges") }}</b>
          </td>
        </template>
        <template v-slot:body="data">
          <td>
            {{ data.model?.payment_method?.name }}
          </td>
          <td>
            {{ data.model?.payment_gateway?.name }}
          </td>
          <td>
            {{ data.model?.channel }}
          </td>
          <td>
            {{ data.model?.gateway_charges_type }}<br />
            <div v-if="data.model?.gateway_charges_type == 'fixed'">
              {{ data.model?.formatted_gateway_fixed_charges }}
            </div>
            <div
              v-else-if="data.model?.gateway_charges_type == 'percentage' && data.model?.gateway_percentage_min_charges > 0">
              {{ data.model?.gateway_percentage_charges }}% / {{ data.model?.formatted_gateway_percentage_min_charges }}
            </div>
            <div
              v-else-if="data.model?.gateway_charges_type == 'percentage' && data.model?.gateway_percentage_min_charges == 0">
              {{ data.model?.gateway_percentage_charges }}%
            </div>
            <div
              v-else-if="data.model?.gateway_charges_type == 'mixed' && data.model?.gateway_percentage_min_charges > 0">
              {{ data.model?.gateway_percentage_charges }}% + {{ data.model?.formatted_gateway_fixed_charges }} / {{
              data.model?.formatted_gateway_percentage_min_charges }}
            </div>
            <div
              v-else-if="data.model?.gateway_charges_type == 'mixed' && data.model?.gateway_percentage_min_charges == 0">
              {{ data.model?.gateway_percentage_charges }}% + {{ data.model?.formatted_gateway_fixed_charges }}
            </div>
          </td>
          <td>
            {{ data.model?.template_charges_type }}<br />
            <div v-if="data.model?.template_charges_type == 'fixed'">
              {{ data.model?.formatted_template_fixed_charges }}
            </div>
            <div
              v-else-if="data.model?.template_charges_type == 'percentage' && data.model?.template_percentage_min_charges > 0">
              {{ data.model?.template_percentage_charges }}% / {{ data.model?.formatted_template_percentage_min_charges
              }}
            </div>
            <div
              v-else-if="data.model?.template_charges_type == 'percentage' && data.model?.template_percentage_min_charges == 0">
              {{ data.model?.template_percentage_charges }}%
            </div>
            <div
              v-else-if="data.model?.template_charges_type == 'mixed' && data.model?.template_percentage_min_charges > 0">
              {{ data.model?.template_percentage_charges }}% + {{ data.model?.formatted_template_fixed_charges }} / {{
              data.model?.formatted_template_percentage_min_charges }}
            </div>
            <div
              v-else-if="data.model?.template_charges_type == 'mixed' && data.model?.template_percentage_min_charges == 0">
              {{ data.model?.template_percentage_charges }}% + {{ data.model?.formatted_template_fixed_charges }}
            </div>
          </td>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script>

import PAYMENT_CHARGE_TYPE from "@/utils/const/payment_charge_type";

export default {
  data() {
    return {
      PAYMENT_CHARGE_TYPE: PAYMENT_CHARGE_TYPE,
      queryParams: "",
      showPaymentGatewayMethodModal: false,
      tabs: [
        { key: "gateway", title: this.$t("payment.payment-providers.header.gateway_charges") },
        { key: "template", title: this.$t("payment.payment-providers.header.template_charges") },
      ],
      selectedTabs: "gateway",
      selectedPaymentGatewayMethod: null,
    };
  },
  computed: {
    apiPaginationLoading() {
      return this.$store.getters["paymentGatewayMethodStore/apiPaginationLoading"];
    },
    paymentGatewayMethods() {
      return this.$store.getters["paymentGatewayMethodStore/payment_gateway_methods"];
    },
    chargesType() {
      return this.$store.getters["paymentStore/chargesType"];
    },
    loadingChargesType() {
      return this.$store.getters["paymentStore/loadingChargesType"];
    },
    loadingUpdate() {
      return this.$store.getters["paymentStore/loading"];
    },
    errorsPayment() {
      return this.$store.getters["paymentGatewayMethodStore/errors"];
    },
    errorsPaymentMessage() {
      return this.$store.getters["paymentGatewayMethodStore/message"];
    },
  },
  mounted() {
    this.fetchListPaymentMethods();
    this.fetchChargesType();
  },
  methods: {
    fetchListPaymentMethods() {
      this.$store.dispatch("paymentGatewayMethodStore/indexPaymentGatewayMethods", this.queryParams);
    },
    fetchChargesType() {
      this.$store.dispatch("paymentStore/retrieveChargesType");
    },
    paramsChanged(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchListPaymentMethods();
    },
    openPaymentGatewayMethodModal(payment_gateway_method) {
      this.selectedPaymentGatewayMethod = this.$lodash.cloneDeep(payment_gateway_method);
      this.selectedPaymentGatewayMethod.gateway_charges_type = this.chargesType.find(
        (charge_type) => charge_type.type == this.selectedPaymentGatewayMethod.gateway_charges_type
      );
      this.selectedPaymentGatewayMethod.template_charges_type = this.chargesType.find(
        (charge_type) => charge_type.type == this.selectedPaymentGatewayMethod.template_charges_type
      );
      this.showPaymentGatewayMethodModal = true;
    },
    closePaymentGatewayMethodModal() {
      this.selectedPaymentGatewayMethod.gateway_charges_type = this.selectedPaymentGatewayMethod.gateway_charges_type.type;
      this.selectedPaymentGatewayMethod.template_charges_type = this.selectedPaymentGatewayMethod.template_charges_type.type;

      this.showPaymentGatewayMethodModal = false;
    },
    async submitBusinessPaymentGatewayMethod() {
      const paymentGatewayMethodResult = await this.$store.dispatch(
        "paymentGatewayMethodStore/updatePaymentGatewayMethods",
        this.selectedPaymentGatewayMethod
      );

      if (paymentGatewayMethodResult == null) {
        this.$notify(
          {
            group: "error",
            title: this.errorsPaymentMessage,
          },
          5000
        );
        return;
      }

      this.$notify(
        {
          group: "success",
          title: "Saved!",
        },
        3000
      );
      const toUpdatePaymentGatewayMethod = this.paymentGatewayMethods.data.find(
        paymentGatewayMethod => paymentGatewayMethod.id === paymentGatewayMethodResult.data.id
      );
      Object.assign(toUpdatePaymentGatewayMethod, paymentGatewayMethodResult.data);
      this.showPaymentGatewayMethodModal = false;
    }
  },
};
</script>
